@import "variables";


//
.rdw-fontsize-wrapper, .rdw-colorpicker-wrapper, .rdw-link-wrapper, .rdw-embedded-wrapper, .rdw-emoji-wrapper, .rdw-image-wrapper, .rdw-remove-wrapper, .rdw-history-wrapper {
  display: none!important;
}
.editorClassName {
  margin-left: 1rem!important;
}
.img-hover-zoom {
  // height: 300px; /* [1.1] Set it as per your need */
  overflow: hidden; /* [1.2] Hide the overflowing of child elements */
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
  transition: transform .5s ease;
  transform: scale(1.5);
}

/* [3] Finally, transforming the image when container gets hovered */
/* The switch - the box around the slider */
.switch-pos {
  position: absolute;
  top: 90px;
  left: 20px;
  z-index: 100;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c53030;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2f855a;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2f855a;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.img-hover-zoom:hover img {
  transform: scale(2);
}
.h-90 {
  height: 25rem;
}
.small-cart {
  position: fixed;
  top: 300px;
  right: 0;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 760px) { 
  .small-cart {
    width: 95%;
    position: fixed;
    top: 56px;
    // bottom: 100px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

 }
.full-cart {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
}
.ReactModal__Overlay {
  z-index: 100;
}
// 
div.scroll { overflow-y: scroll; }


div.scroll::-webkit-scrollbar {
    width: 3px;
  }
  
  /* Track */
  div.scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }
  
  /* Handle */
  div.scroll::-webkit-scrollbar-thumb {
    background: $prim;
    border-radius: 10px;
  }

  div.scroll::-webkit-scrollbar {
      width: 5px;
    }
    
    /* Track */
    div.scroll::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
    }
    
    /* Handle */
    div.scroll::-webkit-scrollbar-thumb {
      background: $prim;
      border-radius: 10px;
    }

div.scroll-x { overflow-x: scroll; }


div.scroll-x::-webkit-scrollbar {
    width: 0;
  }
  
  /* Track */
  div.scroll-x::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }
  
  /* Handle */
  div.scroll-x::-webkit-scrollbar-thumb {
    background: $prim;
    border-radius: 10px;
  }

  div.scroll-x::-webkit-scrollbar {
      height: 3px;
    }
    
    /* Track */
    div.scroll-x::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
    }
    
    /* Handle */
    div.scroll-x::-webkit-scrollbar-thumb {
      background: $prim;
      border-radius: 10px;
    }

body { overflow-y: scroll; }

.category-scrollbar{
  max-height: 500px;
  overflow-y: auto;
}

body::-webkit-scrollbar,
.category-scrollbar::-webkit-scrollbar {
    width: 3px;
  }
  
  /* Track */
  body::-webkit-scrollbar-track ,
  .category-scrollbar::-webkit-scrollbar-track{
    box-shadow: inset 0 0 5px grey;
  }
  
  /* Handle */
  body::-webkit-scrollbar-thumb,
  .category-scrollbar::-webkit-scrollbar-thumb {
    background: $prim;
    border-radius: 10px;
  }

  body::-webkit-scrollbar{
      width: 5px;
    }


  .scrollBar { overflow-y: scroll; }
  
  
  .scrollBar::-webkit-scrollbar {
    height:  3px;
    width:  0;
}

.scrollBar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

.scrollBar::-webkit-scrollbar-thumb {
  background: $prim;
  border-radius: 10px;
}

.scrollBar::-webkit-scrollbar {
    height:  3px;
    width:  0;
  }
  
  .scrollBar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }
  
  .scrollBar::-webkit-scrollbar-thumb {
    background: $prim;
    border-radius: 10px;
  }
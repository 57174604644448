
.update-container{
  width:33.33%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  height:152px;
  margin-top:40px;
  background: #F3F4F6;

}
.textFields-top{
  display: flex;
  justify-content: space-between;
}
.active{
  display:none;
}

.address-text{
  width: 100%;
  height: 48px;
  border:1px solid rgba(185, 28, 28, var(--tw-border-opacity));
  margin-bottom:1px;
  border-radius: 0.2rem;
  padding:5px;
  outline: none;
}
.postCode-text{
  width: 100%;
  height: 33.6px;
  border: 1px solid rgba(185, 28, 28, var(--tw-border-opacity));
  border-radius: 0.2rem;
  padding:5px;
  outline: none;
  margin-bottom:5px;
}
.update-button{
  display: block;
  
}

.address-submit{
  color:white;
  background: rgba(197, 48, 48, var(--tw-bg-opacity));
  padding: 0.5rem 3rem;
  font-size: 0.75rem;
  width:100%;
  font-weight: 600;
  border-radius:0.5rem;
}

.address-lable{
  margin-top: 10px;
}

.name-div{
  display: flex;

}
.name-input{
  background: #f2f2f2;
  width:55%;
  border-radius: 0.5rem;
  padding: 1rem;
  color:black
}


.name{
  width: 130px;
  height: 50px;
  font-weight: 600;
  font-size: 16px;
  margin-left: 10px;
  
}
.customer-name{
  width: 55%;
  font-weight: 600;
}






$prim: #c53030;
$sec: #292929;
$gray: #F2F2F2;

$dark-blue: #21263b;
$blue: #4986ef;
$lig-pink: #FCF7F4;
$half-white: #ebf2f7;
$black: rgb(13, 17, 54);
$light-grays: #ebe9e9bd;
$light-gray: rgba(43, 87, 100, 0);
$dark-gray: #53585e;
$orange: #e6ded97c;
$dark-orange: #ff7d27;
$light-purpal: #ffb8dc;
$purpal: #bf3fde;
$dark-purpal: #6c1879;
$yellow: #ffd778;
$sky: #6bffd2;
$light-sky: #94e2ff;
$dark-sky: #12ab9c;
$sky-green: #1be1b2;
$light-green:#99d40c;
$light-sky-green: #e2fcf6;
$light-blu: #1cc1ff;
$dark-pink: #f23077;
$light-pink: #e412c8;
$red: #ed4c4c;
$light-red: #ffa9ac;